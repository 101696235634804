/** @module application */

import { eventListenerOptions } from '@spinnwerk/polyfills';
import { globalState } from './index';

let handlerOptions = eventListenerOptions({ passive: true, once: false });

export function showFocusOnTabKey() {
    // show focus style only after a tab keystroke
    function handleFirstTab(event) {
        if ('key' in event && event.key === 'Tab') {
            document.body.classList.add('user-is-tabbing');

            if (typeof handlerOptions !== 'object') {
                window.removeEventListener('keydown', handleFirstTab, handlerOptions);
            }

            if (globalState.has('headroom')) {
                globalState.get('headroom').then(Headroom => {
                    Headroom.destroy();
                });
            }
        }
    }

    window.addEventListener('keydown', handleFirstTab, handlerOptions);

    if (process.env.NODE_ENV !== 'production') {
        document.addEventListener(
            'focus',
            ({ target }) => {
                console.group('[Focus]');
                console.log(target);
                console.groupEnd();
            },
            eventListenerOptions({ capture: true }),
        );
    }
}
