/**
 * Note: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, remove the corresponding function calls from here.
 */

// ////////////////////////////////////////////////////////////
// Framework initialization
import * as foundation from './_includes/foundation';

foundation.init();

// ////////////////////////////////////////////////////////////
// Security
import * as security from './lib/security';

security.initSecuringExternalLinks();

// ////////////////////////////////////////////////////////////
// Application setup
import * as application from './lib/application';

// register service worker in production mode
application.serviceWorker();

// optimized font loading
// fonts are defined in .fontsrc.json and automatically added to CONFIG
application.loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.showFocusOnTabKey();
application.fadePage();
application.fontSizeSwitcher();

application.globalState.set(
    'headroom',
    application.initHeadroom({
        // add the spacer only if there is no hero element
        prependSpacerTo: document.querySelector('.off-canvas-content .hero') ? false : '.off-canvas-content',
    }),
);

// init the scroll-up-button
application.scrollUp({
    createButton:
        'createScrollUpButton' in CONFIG ? CONFIG.createScrollUpButton : !document.querySelector('.section-nav'),
});

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    getTargetId(hash) {
        return hash.replace(/#\/?/, '');
    },
});

// hide elements when others appear
application.clearAway({
    target: '.section-nav',
    trigger: '.footer',
});

// filter
application.filters();

// ////////////////////////////////////////////////////////////
// Effects
import * as effects from './lib/effects';

effects.animateSVG();
effects.counter();

// ////////////////////////////////////////////////////////////
// form helpers
import * as form from './lib/form';

// prevent mobile zoom in
form.preventMobileZoom();

function initFormHelpers() {
    // automatically attach a loading indicator to submit buttons on form submit
    form.loadingIndicator();

    // init floating-label-inputs
    form.floatingLabel();

    // enable validation
    form.validation().then(() => {
        form.steps(); // validation needs to be in place before multi-step forms can be initialized
    });

    // enable automatic summaries
    form.summaries();

    // enable conditional inputs
    form.conditionals();

    // enable (de-)selecting all checkboxes at once
    form.selectAll();

    // style advanced file inputs
    form.fileInput({
        multipleFilesLabel: ':anz Dateien ausgewählt',
    });

    // enable multi file uploads
    form.multiUpload();

    // manage hierarchical checkboxes
    form.hierarchicalCheckboxes();
}

initFormHelpers();

// WooCommerce replaces DOM completely, so all functionality must be applied again (-> missing event handlers)
import { eventListenerOptions } from '@spinnwerk/polyfills';
document.body.addEventListener('updated_wc_div', () => initFormHelpers(), eventListenerOptions({ passive: true }));

// ////////////////////////////////////////////////////////////
// layouts
import * as layouts from './lib/layouts';

layouts.masonry();
layouts.swiper();
layouts.map();

// ////////////////////////////////////////////////////////////
// browser alerts
import * as alerts from './lib/alerts';

alerts.oldBrowserAlert();

// ////////////////////////////////////////////////////////////
// init web share api polyfill. Should be loaded as late as possible
application.shareApi();
