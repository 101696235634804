/** @module application */

async function autoScrolling(options) {
    let { AutoScrolling } = await import(/* webpackMode: "lazy" */ './auto-scrolling');

    return AutoScrolling.singleton(options);
}

async function initHeadroom(options) {
    let { Headroom } = await import(/* webpackMode: "lazy" */ './headroom');

    return new Headroom(options);
}

import { bugsnag } from './bugsnag';
import { clearAway } from './clear-away';
import { fadePage } from './fade-page';
import { filters } from './filters';
import { fontSizeSwitcher } from './font-size-switcher';
import { globalState } from './globalState';
import { loadFonts } from './fonts';
import { serviceWorker } from './service-worker';
import { scrollUp } from './scroll-up';
import { showFocusOnTabKey } from './show-focus-on-tab-key';
import { shareApi } from './web-share';

export {
    autoScrolling,
    bugsnag,
    clearAway,
    fadePage,
    filters,
    fontSizeSwitcher,
    globalState,
    initHeadroom,
    loadFonts,
    serviceWorker,
    scrollUp,
    showFocusOnTabKey,
    shareApi,
};
