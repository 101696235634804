/** @module form */

import { toElements } from '../utils';

/**
 * Manages conditional inputs in forms.
 * Note: Conditional inputs may not be <code>required</code>, validation does not work properly for them.
 *
 * @returns {Promise<void>} - Resolves immediately if no form with conditional inputs is on the current page, or once all
 *  inputs are initialized.
 */
export async function conditionals() {
    let conditionals = toElements('form [data-form-conditional]');

    if (conditionals.length) {
        let { initConditionals } = await import(/* webpackMode: "lazy" */ './inc/conditionals.async');

        initConditionals(conditionals);
    }
}
