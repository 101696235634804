/**
 * @module security
 */

import { toElements } from '../utils';

function isExternalDomain(url) {
    let protocolRegex = /^(https?:)?\/\//;

    if (protocolRegex.test(url)) {
        let protocol = url.match(protocolRegex),
            domain = url.substr(protocol[0].length).split(/[/?]/)[0];

        return domain !== location.hostname;
    }

    return false;
}

function addRelAttributeNoopenerNoreferrer(el) {
    let rel = el.getAttribute('rel');

    if (rel) {
        rel = rel.split(' ');
    } else {
        rel = [];
    }

    rel = rel.concat(['noopener', 'noreferrer']);
    rel = [...new Set(rel)]; // filter duplicates
    el.setAttribute('rel', rel.join(' '));
    el.dataset.sec = '1';
}

function secureExternalLinks() {
    toElements(
        `a[target="_blank"]:not([data-sec="1"]),
        area[target="_blank"]:not([data-sec="1"]),
        form[target="_blank"]:not([data-sec="1"])`,
    ).forEach(el => {
        let target = el.href || el.action;

        if (isExternalDomain(target)) {
            // do not add the rel attribute to all _blank-links,
            // as same-domain-links may get some performance boost by browsers from not adding these values
            addRelAttributeNoopenerNoreferrer(el);
        }
    });
}

/**
 * Checks all links and forms with attribute <code>target</code> set to <code>'_blank'</code> and an attribute
 * <code>action</code> resp. <code>href</code> linking to a different domain whether their rel-attribute contains
 * <code>'noopener noreferrer'</code> and sets it, if required. Checked links are marked with attribute
 * <code>data-sec</code> set to <code>'1'</code>. The check is performed when the script is loaded and whenever the
 * readystate of the document changes, so that all links are marked early and probably get some links
 * added by other scripts.<br>
 * Note: Adding these values to *all* elements with attribute <code>target="_blank"</code> can have performance
 * impacts, as browser may optimize new tabs/windows opened for same-domain links, what is not the case when they were
 * opened without opener an referrer.
 * @listens readystatechange
 * @todo Check if usage of MutationObserver would be appropriate or overdone.
 */
export function initSecuringExternalLinks() {
    // secure links instantly
    secureExternalLinks();

    // and whenever the readystate of the document changes to not miss anything
    document.addEventListener('readystatechange', secureExternalLinks);
}
