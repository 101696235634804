import * as utils from '../utils';

/**
 * Clear away elements when other elements are in view.
 *
 * @param {object} options
 * @param {string|HTMLElement|NodeList|Array<string|HTMLElement|NodeList>} options.trigger - Which element(s) trigger the clearing.
 * @param {string|HTMLElement|NodeList|Array<string|HTMLElement|NodeList>} options.target - Which element(s) should be cleared away.
 * @param {boolean} [options.whenInView=false] - Whether the clearing should be triggered when <code>options.trigger</code> is in or out of view.
 * @param {boolean} [options.limitedObservationArea=true] - Whether the observation area should be limited to the largest rectangle all elements defined by `option.target` span on the screen (default), or the complete viewport should be used (`false`).
 */
export async function clearAway({ trigger, target, whenInView = false, limitedObservationArea = true }) {
    trigger = utils.toElements(trigger);
    target = utils.toElements(target);

    if (trigger.length && target.length) {
        let { initClearAway } = await import(/* webpackMode: "lazy" */ './inc/clear-away.async');

        initClearAway({ trigger, target, whenInView, limitedObservationArea });
    }
}
