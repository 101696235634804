/** @module application */

/**
 * Manages filtering items.
 *
 * @returns {Promise<void>} Resolves immediately if no filters need to be managed, or once the required script loaded.
 */
export async function filters() {
    const filters = document.querySelectorAll('.filter');

    if (filters.length) {
        const { initFilters } = await import(/* webpackMode: "lazy" */ './inc/filters.async');

        initFilters(filters);
    }
}
