/** @module utils */

/**
 * Ensures that the given element(s) or selector(s) is actually an array of <code>HTMLElement</code>s.
 *
 * @param {string|HTMLElement|NodeList|Array<string|HTMLElement|NodeList>} element - Selector(s) or element(s) to convert
 *
 * @returns {HTMLElement[]} - The resulting Array of <code>HTMLElement</code>s.
 */
export function toElements(element) {
    let elements = [];

    if (!Array.isArray(element) && !(element instanceof NodeList)) {
        element = [element];
    }

    element.forEach(el => {
        if (!el) {
            return;
        }

        if (typeof el === 'string') {
            elements = elements.concat(Array.prototype.slice.call(document.querySelectorAll(el)));
        } else if (el instanceof HTMLElement) {
            elements.push(el);
        } else if (element instanceof NodeList) {
            elements = elements.concat(Array.prototype.slice.call(el));
        }
    });

    return elements;
}
