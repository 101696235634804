/** @module form */

import { toElements } from '../utils';

/**
 * Manages forms with multiple steps.
 * @param {boolean} [withValidation=true] - Whether each step's input should be validated before moving to the next
 *  step. If <code>true</code>, ensure parsley is loaded upfront.
 *
 * @returns {Promise<void>} - Resolves immediately if no form with multiple steps is on the current page, or once all
 *  forms are initialized.
 */
export async function steps(withValidation = true) {
    let multiStepForms = toElements('form [data-steps]');

    if (multiStepForms.length) {
        let { initSteps } = await import(/* webpackMode: "lazy" */ './inc/steps.async');

        initSteps(multiStepForms, withValidation);
    }
}
